var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"input-group mb-3 input-group-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Filter..."},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon2"}},[_c('b-icon-filter')],1)])]),_c('hr'),_c('router-link',{staticClass:"w-100 mb-1 btn-product",class:{ active: _vm.$route.name === 'projects-single' },attrs:{"tag":"div","to":{ name: 'projects-single', params: { uuid: _vm.project.uuid } }}},[_vm._v(" Projektübersicht ")]),_c('hr'),_vm._l((_vm.products),function(p){return _c('ProductListCategory',{key:p.model.entity,attrs:{"title":p.title,"filter":_vm.filter,"display":p.display,"project":_vm.project,"product":p.model,"modal":p.create},scopedSlots:_vm._u([{key:"cloud_servers",fn:function(ref){
var product = ref.product;
return [_vm._v(" "+_vm._s(product.id)+" ( "+_vm._s(product.title || product.hostname || 'Unbenannt')+" ) ")]}},{key:"networks",fn:function(ref){
var product = ref.product;
return [_vm._v(" "+_vm._s(product.id)+" ")]}},{key:"ip_addresses",fn:function(ref){
var product = ref.product;
return [_vm._v(" "+_vm._s(product.address)+" "),_c('span',{staticClass:"float-right"},[_vm._v("("+_vm._s(product.reserved ? "genutzt" : "frei")+")")])]}},{key:"firewall_rule_sets",fn:function(ref){
var product = ref.product;
return [_vm._v(" "+_vm._s(product.title)+" ")]}},{key:"firewall_address_sets",fn:function(ref){
var product = ref.product;
return [_vm._v(" "+_vm._s(product.title)+" ")]}}],null,true)})}),_c('hr'),_c('CloudServerCreateModal',{attrs:{"name":"cloud-server-create-modal"}}),_c('AddressCreateModal',{attrs:{"name":"address-create-modal"}}),_c('FirewallAddressSetCreateModal',{attrs:{"name":"firewall-address-set-create-modal"}}),_c('FirewallRuleSetCreateModal',{attrs:{"name":"firewall-rule-set-create-modal"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }