<template>
    <b-modal
        id="cloud-server-create-modal"
        ref="modal"
        title="Cloud Server bestellen"
        :hide-footer="true"
        back
        size="xl"
    >
        <CloudServerCreateForm v-model="configuration" @confirm="order" />
    </b-modal>
</template>

<script>
import CloudServerCreateForm from './CloudServerCreateForm'
import { CloudServer } from '@/core/services/store/models/cloud_server'
export default {
    name: 'CloudServerCreateModal',
    components: { CloudServerCreateForm },
    data () {
        return {
            configuration: {
                cores: 1,
                memory: 1,
                storages: [{
                    size: 10,
                    replication: 2
                }],
                interfaces: [{
                    ipv4: [],
                    primary_ipv4: null,
                    is_public: true,
                    has_firefall: false,
                    network_id: undefined
                }]
            }
        }
    },
    methods: {
        async order() {
            const response = await this.api.createCloudServer({ ...this.configuration, memory: this.configuration.memory * 1024 })
            await CloudServer.insert(response)
            this.$refs.modal.hide()
            await this.$router.push({ name: 'servers-single', params: { id: response.data.id } })
        }
    }
}
</script>

<style scoped>

</style>
