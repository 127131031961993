<template>
    <form
        @submit.prevent="$emit('confirm')"
        @change="change"
        @click="change"
        @keydown="change"
    >
        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.COMPUTE_POWER') }}</h4>
        <b-row>
            <b-col v-for="(option, index) in ['cores', 'memory']"
                   :key="index"
                   class="mb-10"
            >
                <div v-if="Object.values(options).length > 0" class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit v-model="value[option]"
                                    :step="options[option].step"
                                    :min="options[option].min_units"
                                    :max="options[option].max_units"
                                    :name="option"
                                    :title="$tc(`DATACENTER.VSERVERS.CONFIGURATION.${option.toUpperCase()}`, 2)"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>

        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.DISKS.DESC', {n: value.storages.length, max: 5}) }}</h4>
        <div v-if="options['disk'] !== undefined" class="row mb-10 position-relative">
            <div v-for="(storage, index) in value.storages"
                 :key="index"
                 class="col-md-4 mt-3"
            >
                <CloudServerDriveConfigurator :options="options['disk']" :deletable="value.storages.length > 1" :storage="storage" :index="index" @delete="value.storages.splice(index, 1)" />
            </div>
            <div class="col-md-4 mt-3">
                <div v-if="value.storages.length < 5"
                     class="card card-custom card-stretch opacity-50 hover-opacity-80 cursor-pointer"
                     style="min-height: 130px"
                     @click="addStorage"
                >
                    <div class="text-center"
                         style="position: absolute;display: inline;left:50%;top: 50%;transform: translate(-50%,-50%); font-size: 50px;"
                    >
                        <b-icon-plus />
                    </div>
                </div>
            </div>
        </div>

        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.INTERFACES.DESC', {n: value.interfaces.length, max: 10}) }}</h4>
        <div class="row mb-10 position-relative">
            <div v-for="(network, index) in value.interfaces"
                 :key="index"
                 class="col-md-4 mt-3"
            >
                <CloudServerNetworkConfigurator :network="network" @delete="value.interfaces.splice(index, 1)" />
            </div>
            <div class="col-md-4 mt-3">
                <div v-if="value.interfaces.length < 10"
                     class="card card-custom card-stretch opacity-50 hover-opacity-80 cursor-pointer"
                     style="min-height: 130px"
                     @click="addInterface"
                >
                    <div class="text-center"
                         style="position: absolute;display: inline;top: 50%;left:50%;transform: translate(-50%,-50%); font-size: 50px;"
                    >
                        <b-icon-plus />
                    </div>
                </div>
            </div>
        </div>

        <div class="float-left">
            <b-button
                variant="primary"
                type="submit"
                size="lg"
                :disabled="$wait.is('vserver-create')"
            >
                <i v-if="$wait.is('vserver-create')"
                   class="fa fa-spinner fa-spin"
                />

                {{ submitTitle }}
            </b-button>
        </div>
        <div class="float-right h3">
            {{ $t('DATACENTER.VSERVERS.PER_DAY') }}: {{ (Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price/30)) }}<br>
            {{ $t('DATACENTER.VSERVERS.PER_MONTH') }}: {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price) }}
        </div>
    </form>
</template>

<script>
import FormatValues from '@/core/mixins/formatValues'
import SliderEdit from '@/view/components/helper/SliderEdit'
import { IpAddress } from '@/core/services/store/models/ip_address'
import projectMixin from '@/core/mixins/projectMixin'
import customerMixin from '@/core/mixins/customerMixin'
import CloudServerDriveConfigurator from '@/view/components/cloudserver/configurators/CloudServerDriveConfigurator'
import CloudServerNetworkConfigurator from '@/view/components/cloudserver/configurators/CloudServerNetworkConfigurator'
import CloudServerPricing from '@/core/services/store/models/cloud_server_pricing'

const DEFAULT_CONFIG = {
    cores: 1,
    memory: 1,
    interfaces: [],
    storages: []
}

export default {
    name: 'CloudServerCreateForm',
    components: { CloudServerNetworkConfigurator, CloudServerDriveConfigurator, SliderEdit },
    mixins: [FormatValues, projectMixin, customerMixin],
    props: {
        value: {
            type: Object,
            default: () => DEFAULT_CONFIG
        },
        submitTitle: {
            type: String,
            default: 'Bestätigen'
        }
    },
    data: () => ({
        options: [],
        freeIpAddresses: []
    }),
    computed: {
        price () {
            let price = 0
            price += this.calculateOption('cores')
            price += this.calculateOption('memory')

            let disk_size = 0
            // eslint-disable-next-line array-callback-return
            this.value.storages.map(item => {
                disk_size += parseInt(item.size)
            })
            price += this.calculateOption('disk', disk_size)

            return price
        }
    },
    methods: {
        addInterface() {
            this.value.interfaces.push({
                ipv4: [],
                primary_ipv4: null,
                is_public: true,
                has_firefall: false,
                network_id: undefined
            })
        },
        addStorage() {
            this.value.storages.push({ size: 10, replication: 2 })
        },
        async loadPricing () {
            await CloudServerPricing.fetch(this.customer.id, this.project.uuid)

            this.options = CloudServerPricing.allAsObject()
            this.options.memory = await CloudServerPricing.getByUnit('memory')

            // eslint-disable-next-line array-callback-return
            Object.values(this.options).map(el => {
                if (this.value[el.unit] !== undefined) {
                    // eslint-disable-next-line array-callback-return
                    return
                }

                this.value[el.unit] = el.min_units
            })
            this.change()
        },
        calculateOption (key, value) {
            const entry = this.options[key]
            if (entry === undefined) {
                return 99999
            }

            let units = (value || this.value[key]) - entry.free_units
            if (units < 0) {
                units = 0
            }

            return units / entry.step * entry.price
        },
        change () {
            const cloudServer = {
                interfaces: this.value.interfaces.map(item => {
                    return {
                        ...item,
                        ipv4: item.primary_ipv4 ? [item.primary_ipv4] : undefined
                    }
                }),
                storages: this.value.storages,
                template: 'DEBIAN_10',
                fast: true,
                cores: this.value.cores,
                memory: this.value.memory,
                project_uuid: this.project.uuid,
                customer_id: this.customer.id, // FUCK whoever made the backend here (see CreateRequest line 39)
                id: this.value.id
            }

            this.$emit('input', cloudServer)
        }
    },
    created () {
        IpAddress.fetchAll()
        this.loadPricing()
    }
}
</script>

<style scoped>
    .card {
        background: #eef0f8;
    }

    .custom-input {
        border: none;
        border-bottom: 1px solid black;
        border-radius: 0;
        background: transparent;
        font-size: 16px;
        padding: 0;
        height: calc(1.3em + 1rem);
    }
</style>
