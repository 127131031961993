<template>
    <div class="card card-custom card-stretch">
        <div class="card-body">
            <b-form-radio-group
                id="btn-radios-2"
                v-model="network.is_public"
                buttons
                button-variant="outline-primary"
                size="sm"
                name="radio-btn-outline"
                :options="[{ text: 'Internet', value: true },{ text: $t('DATACENTER.VSERVERS.CONFIGURATION.INTERFACES.PRIVATE_NETWORK'), value: false }]"
                class="mb-10 d-flex"
                @change="network.network_id = null; network.primary_ipv4 = undefined; network.custom_address = undefined; network.custom_gateway = undefined;"
            />

            <div v-if="!network.is_public">
                <label>Netzwerk</label>
                <b-form-select
                    v-if="!toggleNetworkList"
                    :value="network.network_id"
                    :label="$t('DATACENTER.VSERVERS.CONFIGURATION.INTERFACES.CHOOSE_NETWORK')"
                    :options="networks.map(n => ({ value: n.id, text: n.title + (n.id ? ' (ID: ' + n.id + ')' : '') }))"
                    @change="value => { network.network_id = parseInt(value) }"
                />
            </div>

            <div class="">
                <template v-if="network.is_public">
                    <label>Adresse</label>
                    <b-form-select
                        ref="massSelect"
                        :value="network.primary_ipv4"
                        clearable
                        :options="freeAddresses.map(a => ({ value: a.address, text: a.address }))"
                        label="IP auswählen"
                        @change="elem => { const vlan = freeAddresses.find(e => e.address === elem).vlan_id; if (vlan !== 500) network.network_id = vlan; network.primary_ipv4 = elem }"
                    />
                </template>
                <template v-else-if="network.is_public === false">
                    <label>Adresse</label>
                    <b-form-input
                        v-model="network.custom_address"
                        :label="$t('DATACENTER.VSERVERS.CONFIGURATION.NETWORKS.IP_ADDRESS')"
                        placeholder="10.12.12.5/24"
                    />
                </template>
            </div>

            <div class="mt-2 text-center font-italic">
                <b-button
                    variant="link"
                    size="sm"
                    style="position: absolute; right: -20px; top: -20px;"
                    tabindex="-1"
                    class="text-decoration-none"
                    @click="$emit('delete')"
                >
                    <span style="width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background: rgba(0, 0, 0, .5); font-size: 1.5em;">
                        <b-icon-x style="fill: white;" />
                    </span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { IpAddress } from '@/core/services/store/models/ip_address'
import { Network } from '@/core/services/store/models/network'

export default {
    name: 'CloudServerNetworkConfigurator',
    props: {
        network: {
            type: Object,
            required: true
        },
        deletable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            toggleNetworkList: false
        }
    },
    computed: {
        freeAddresses() {
            return IpAddress.query().where('reserved', null).where('type', null).get()
        },
        networks () {
            return Network.all().filter(el => !el.is_public)
        }
    },
    created() {
        Network.fetchAll()
    }
}
</script>

<style scoped>

</style>
